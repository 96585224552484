function Hello() {
    return (
        <div id="hello">
            {/*  when it's time to draft */}
            {/* <p> The draft is open!<br/><br/> The next tournament begins on September 8th, so make sure you draft before then!</p> */}

            {/* when the tournament is live */}
            <p>The tournament has started, and the draft is closed! The next draft will begin after the banzuke is announced for the next tournament.</p>

            {/* if you want to let someone contact you for some reason */}
            {/* <p>(If you picked someone that's out of the tournament, feel free to email me at mtfujisumo at gmail.com with your team name and new pick.)</p> */}

            {/* after the tournament */}
            {/* <p>Congrats to Tobi'sPizzaDelivery on your Fantasy Sumo victory!</p><p> The new rankings will be announced on August 26th, so the draft will be open from August 27th - September 8th.</p><p>I just added a new table of User Rankings, where you can view everyone's past Fantasy Sumo teams, sort by averages & bashos, and more. Check it out by clicking the "User Rankings" button on the Database page.</p><p>I also updated the database with hundreds of past rikishi, you can check out their stats and Fantasy Sumo history going back to 1958!</p><p>Thanks for playing everyone!</p> */}
        </div>
    )
}

export default Hello
